import { TransactionData } from '@payment-app/core/services/transaction-data/transaction-data';
import { PaymentConfigModel } from '@core/models/payment-config.model';

export const transactionDataMapper = (
  paymentRequest: PaymentConfigModel,
  transactionId: string,
): TransactionData => {
  return {
    customerData: {
      idType: paymentRequest.data.id_type,
      idValue: paymentRequest.data.id,
      typePerson: paymentRequest.data.type_user,
      name: `${paymentRequest.data.name} ${paymentRequest.data.last_name}`,
      email: paymentRequest.data.email,
      phone: paymentRequest.data.phone,
      address: '',
    },
    portal: {
      id: '',
      name: paymentRequest.name,
      configuration: {
        credentials: {
          nuvei: {
            code: paymentRequest?.paymentes_credentials?.code_app_client,
            secret: paymentRequest?.paymentes_credentials?.secret_client_app,
          },
        },
        banks: paymentRequest.banks as { code: string; name: string }[],
      }
    },
    transaction: {
      id: transactionId,
      amount: paymentRequest.data.amount,
      date: '',
      description: paymentRequest.data.description,
      reference: paymentRequest.data.reference,
      status: paymentRequest.data.status,
      responseUrl: paymentRequest.data.url_return,
      errorResponseUrl: paymentRequest.data.url_return_problem,
      vat: paymentRequest.data.vat,
    },
  };
};
