export interface TransactionData {
  customerData: CustomerData;
  portal: PortalData;
  transaction: Transaction;
}

export interface CustomerData {
  idValue: string;
  idType: string;
  typePerson: string;
  name: string;
  email: string;
  phone: string;
  address: string;
}

export interface PortalData {
  id: string;
  name: string;
  configuration: PortalConfiguration;
}

export interface PortalConfiguration {
  credentials: {
    nuvei: {
      code: string;
      secret: string;
    };
  };
  banks: { code: string; name: string }[];
}

export interface Transaction {
  id: string;
  amount: number;
  date: string;
  description: string;
  reference: string;
  status: string;
  responseUrl?: string;
  errorResponseUrl?: string;
  vat: number;
}
