import { Injectable, Output, EventEmitter } from '@angular/core';
import { PaymentConfigModel } from '../models/payment-config.model';
import { AuthService } from './auth.service';
import { ChangeStatusService } from './change-status.service';
import { EncryptService } from './encrypt.service';
import { EpaycoService } from './epayco.service';
import { LoadingScreenService } from './loading-screen.service';
import { PaymentTransactionService } from './payment-transaction.service';
import { SessionStorageService } from './session-storage.service';
import * as moment from 'moment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class EpaycoApifyService {
  @Output() message: EventEmitter<any> = new EventEmitter();
  @Output() tokenizeErrorMessage: any = new EventEmitter<string>();
  @Output() activateChip: EventEmitter<any> = new EventEmitter();
  @Output() changeAmountInitial: EventEmitter<any> = new EventEmitter();
  @Output() cancel_bonus: EventEmitter<any> = new EventEmitter();
  private activate_mixed: boolean;
  constructor(
    private encryptService: EncryptService,
    private paymentTransactionService: PaymentTransactionService,
    private epaycoService: EpaycoService,
    private authService: AuthService,
    private changeStatusService: ChangeStatusService,
    private loadingScreenService: LoadingScreenService,
    private sessionStorageService: SessionStorageService,
    private _router: Router,
  ) {
    this.epaycoService.activateMixed.subscribe(mixed_activate => {
      this.activate_mixed = mixed_activate;
    })

  }

  responseSet(status: number) {
    if (status === 1) {
      return 'success';
    } else {
      if (status === 3) {
        return 'pending';
      } else {
        return 'failure';
      }
    }
  }

  franchiseSet(franchise: string) {
    switch (franchise) {
      case 'AM': return 'AMEX';
      case 'BA': return 'BALOTO';
      case 'CR': return 'REDENCIAL';
      case 'DC': return 'DINERS CLUB';
      case 'EF': return 'EFECTY';
      case 'GA': return 'GANA';
      case 'PR': return 'PUNTO RED';
      case 'RS': return 'RED SERVI';
      case 'MC': return 'MASTERCARD';
      case 'PSE': return 'PSE';
      case 'SP': return 'SAFETYPAY';
      case 'VS': return 'VISA';
    }
  }
  async transactionCard(token: string, request: any, paymentRequest: PaymentConfigModel, attempts: number = 0) {
    const emision = {
      message: '',
      origin: 'CARD',
      errorsArray: [],
      errorsString: ''
    };


    let errorMessage = 'Fallo al tokenizar';
    let responseTokenize;
    let save_card = request.save_card ;

    if (paymentRequest.data.plan || paymentRequest.tokenizeCard || save_card) {
      responseTokenize = await this.getTokenizedCard(token, request, paymentRequest);
    }

    let audit = {
      reference: paymentRequest.data.reference,
      customer_email: paymentRequest.data.email,
      client: paymentRequest.name,
      payment_reference: paymentRequest.data.payment_reference,
      response_token: null,
      error: null,
      dev_message: null
    };

    const bonusResult = await this.epaycoService.validarBono(paymentRequest);

    if (bonusResult || !(paymentRequest.manual_bonus && paymentRequest.manual_bonus_voucher)) {
      const cnumber = request.card.number.replace(/\D+/g, '');
      let mask = cnumber.substr(cnumber.length - 4);

      try {
        mask = this.encryptService.encryptStringData(mask);
      } catch (error) {
        console.log('no se pudo enmascarar la tarjeta');
      }

      if (request.token_card || !responseTokenize) {
        let transaction = this.epaycoService.BuildJsonCard(request, paymentRequest);
        if (!responseTokenize) this.addInfoTransaction(transaction, request);
        this.authService.getTimezone().subscribe(
          success => {
            try {
              transaction.hash = this.encryptService.encryptCipherData(success);
              transaction.mask = mask;
              transaction.status = "loaded";

              this.paymentTransactionService
                .postTransaction(transaction, "Bearer " + token)
                .subscribe(
                  (res: any) => {
                    if (res.errors) {
                      if (res.errorsMapping) {
                        emision.message = res.errorsMapping.description;
                        if (Array.isArray(res.errorsMapping.errors)) {
                          emision.errorsArray = res.errorsMapping.errors;
                        }
                        if (!Array.isArray(res.errorsMapping.errors)) {
                          emision.errorsString = res.errorsMapping.errors;
                        }
                      } else if (Array.isArray(res.errors)) {
                        emision.message = res.errors[0].errorMessage;
                      } else {
                        if (res.meta) {
                          this.activateChip.emit(false);
                          this.changeAmountInitial.emit(paymentRequest.aux_init_amount);
                        }
                        emision.message = res.errors;
                      }
                      this.message.emit(emision);
                      this.loadingScreenService.stopLoading();
                    }
                    else {
                      this.sessionStorageService.setItem(SessionStorageService.TRANSACTION_ID, res.data.transactionID);

                      let _status = this.responseSet(res.data.cod_respuesta);
                      let _franquicia = this.franchiseSet(res.data.franquicia);
                      let _res = {
                        transaction: {
                          payment_date: moment(res.data.date).toISOString(),
                          installments: request.dues ? request.dues : '1',
                          trazability_code: res.data.autorizacion,
                          status: _status,
                          type_canceled: res.data.type_canceled,
                          authorization_code: res.data.autorizacion,
                          payment_reference: res.data.payment_reference,
                          plan: res.data.plan ?
                            {
                              recurrence: res.data.plan.recurrence,
                              next_payment: res.data.plan.current_period_end,
                              vigence: {
                                date_in: res.data.plan.vigence.date_in,
                                date_end: res.data.plan.vigence.date_end
                              }
                            } :
                            undefined,
                          puntos_colombia: res.data.puntos_colombia,
                          mixed_purchase: res.data.mixed_purchase
                        },
                        card: {
                          number: '',
                          type: _franquicia
                        }

                      };
                      setTimeout(() => {
                        this.loadingScreenService.stopLoading();


                        if (this.activate_mixed) {
                          this.epaycoService.openPurchasePaymentMix(paymentRequest, _res);
                        } else {
                          this._router.navigate(['/pagos/respuesta'], {
                            state: { paymentRequest: paymentRequest, response: _res }
                          });
                        }

                      }, 100);
                    }
                  },
                  err => {
                    console.log(err);
                    audit.dev_message = "Ocurrio un error al intentar realizar el pago, Intente nuevamente.";
                    emision.message = 'Ocurrio un error al intentar realizar el pago, Intente nuevamente.';
                    this.message.emit(emision);
                    this.changeStatusService.auditErrorStatusAttempted(audit, paymentRequest.data, token);
                    this.loadingScreenService.stopLoading();
                  }
                );
            }
            catch (error) {
              console.log(error);
              audit.dev_message = "Ocurrio un error al intentar realizar el pago, Intente nuevamente.";
              emision.message = 'Ocurrio un error al intentar realizar el pago, Intente nuevamente.';
              this.changeStatusService.auditErrorStatusAttempted(audit, paymentRequest.data, token);
              this.loadingScreenService.stopLoading();
              this.message.emit(emision);
            }
          });
      } else {
        this.loadingScreenService.stopLoading();
        emision.message = responseTokenize.data.error.description;
        if (Array.isArray(responseTokenize.data.error.errors)) {
          emision.errorsArray = responseTokenize.data.error.error;
        }
        if (!Array.isArray(responseTokenize.data.error.errors)) {
          emision.errorsString = responseTokenize.data.error.errors;
        }
        // audit.dev_message = error ? error : 'Fallo al tokenizar tu tarjeta';
        this.changeStatusService.auditErrorStatusAttempted(audit, paymentRequest.data, token);
        this.message.emit(emision);
      }
    }
    else {
      this.cancel_bonus.emit(true);
      this.loadingScreenService.stopLoading();
      return false;
    }
  }
  async tokenizedCard(token: string, request: any, paymentRequest: PaymentConfigModel){
    const requestTokenizer = {
      "card_number":request.card.number.replace(/\s/g, ""),
      "card_exp_year":request.card.year,
      "card_exp_month":request.card.month,
      "card_cvc":request.card.cvc,
      "client": paymentRequest.name,
      "dev_reference": paymentRequest.data.reference,
      "subclient": paymentRequest.data.subclient ? paymentRequest.data.subclient : null,
      "save_card": request.save_card,
      "plan": paymentRequest.data.plan,
      "codeService": paymentRequest.data.codeService ?? null,
    }
    const encrypCardInfo = this.encryptService.encryptCipherData(requestTokenizer);
    return await this.paymentTransactionService.postTransactionTokenize(encrypCardInfo,"Bearer " + token).toPromise();
  }

  async getTokenizedCard(token: string, request: any, paymentRequest:PaymentConfigModel) {
    const response: any = await this.tokenizedCard(token, request, paymentRequest)
    const emision = {
      message: '',
      origin: 'CARD',
      errorsArray: [],
      errorsString: ''
    };
    let errorMessage = 'Fallo al tokenizar';
    let audit = {
      reference: paymentRequest.data.reference,
      customer_email: paymentRequest.data.email,
      client: paymentRequest.name,
      payment_reference: paymentRequest.data.payment_reference,
      response_token: null,
      error: null,
      dev_message: null
    };

    if (response.success) {
      audit.error = null;
      audit.dev_message = 'respondio tokenize apify pendiente a realizar el pago';
    } else {
      audit.error = true;
      audit.dev_message = response.message;
    }

    audit.response_token = response.data.token ? response.data.token : null;
    audit.reference = paymentRequest.data.reference;
    audit.client = paymentRequest.data.client;
    audit.payment_reference = paymentRequest.data.payment_reference;
    this.epaycoService.auditEpaycoResponse(audit, token);
    if(response.success){
      request.token_card = response.data.token;
    }else{
      emision.message = response.data.error.description;
      if(Array.isArray(response.data.error.errors)){
        emision.errorsArray = response.data.error.error;
      }
      if(!Array.isArray(response.data.error.errors)){
        emision.errorsString = response.data.error.errors;
      }
      this.tokenizeErrorMessage.emit(emision);

      audit.dev_message = response.data.error.description;
      this.changeStatusService.auditErrorStatusAttempted(audit, paymentRequest.data, token);
      // console.log("wrong answer: ", response.message)
    }
    return response;
  }

  addInfoTransaction(transaction:any, request:any){

    transaction.card_name = request.card.name;
    transaction.card_num = request.card.number.replace(/\D+/g, '');
    transaction.card_cvc = request.card.cvc;
    transaction.card_exp_month = request.card.month;
    transaction.card_exp_year = request.card.year;
  }
}
